<script setup lang="ts">
const { t: $t } = useI18n()

import type { FormInst } from 'naive-ui'
import { z } from 'zod'

useHead({ title: 'H-OS - Login' })
definePageMeta({
  layout: 'auth',
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/',
  },
})

const { signIn } = useAuth()
const isMobile = useIsMobileScreen()

// Get redirect query
const parsedQuery = z.object({ callbackUrl: z.string().nullish() }).safeParse(useRoute().query)

const isLoading = ref(false)
function triggerSignIn() {
  isLoading.value = true

  let callbackUrl = '/'
  if (parsedQuery.success && parsedQuery.data.callbackUrl) {
    callbackUrl = parsedQuery.data.callbackUrl
  }

  return signIn('azure-ad', { callbackUrl })
}

const error = useRoute().query.error
if (error && error !== 'undefined') {
  const notification = useNotification()
  notification.info({ duration: 5000, description: $t('user.registration.login.failed.error.message', { error }) })
}

// E-Mail sign in
function triggerSignInEmail(email: string | null) {
  if (email === null) {
    return
  }
  isLoading.value = true

  let callbackUrl = '/'
  if (parsedQuery.success && parsedQuery.data.callbackUrl) {
    callbackUrl = parsedQuery.data.callbackUrl
  }

  return signIn('email', { email, callbackUrl })
}

const isEmailSignOnFlow = ref<false | 'started' | 'finished'>(false)
interface ModelType {
  email: string | null
}
const formRef = ref<FormInst | null>(null)
const formData = ref<ModelType>({
  email: null,
})

const mailSchema = z.string().email()
const isValidEmail = (email: string | null) => mailSchema.safeParse(email).success

const query = useRoute().query
if (query.callbackUrl?.includes('verify-request')) {
  isEmailSignOnFlow.value = 'finished'
}
</script>

<template>
  <AuthFooterWrapper :is-mobile="isMobile">
    <div class="relative mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-12 rounded sm:px-10 border">
        <div v-if="isEmailSignOnFlow === false" class="space-y-6">
          <p class="text-base">
            {{ $t('user.registration.description') }}
          </p>

          <div class="flex flex-col gap-4">
            <n-button size="large" type="primary" class="grow" @click="isEmailSignOnFlow = 'started'">
              <span class="font-medium">
                {{ $t('user.registration.signIn.email') }}
              </span>
            </n-button>

            <n-divider class="!my-2">
              <span class="text-sm opacity-50">
                {{ $t('user.registration.noAccount.description') }}
              </span>
            </n-divider>

            <NuxtLink to="/auth/register">
              <n-button size="large" type="primary" tertiary block>
                <span class="font-medium">
                  {{ $t('user.registration.register.withEmail') }}
                </span>
              </n-button>
            </NuxtLink>

            <n-button size="large" type="primary" quaternary class="grow" :loading="isLoading" @click="triggerSignIn">
              <div class="font-medium underline">
                <template v-if="isLoading">
                  {{ $t('user.registration.inProgress') }}
                </template>
                <p v-else class="flex items-center gap-1 text-sm">
                  <span v-if="isMobile">
                    {{ $t('user.registration.signIn.account.mobile') }}
                  </span>
                  <span v-else>
                    {{ $t('user.registration.signIn.account') }}
                  </span>
                </p>
              </div>
            </n-button>
          </div>
        </div>

        <n-form v-else-if="isEmailSignOnFlow === 'started'" ref="formRef" :model="formData" :rules="{ email: { required: true } }" class="flex flex-col gap-2">
          <div class="flex flex-col gap-3">
            <p class="text-base">
              {{ $t('user.registration.emailAddress.enter') }}
            </p>

            <n-input
              v-model:value="formData.email"
              type="text"
              :placeholder="$t('user.registration.emailAddress.placeholder')"
              autofocus
              :disabled="isLoading"
              size="large"
            />
            <n-button
              attr-type="submit"
              type="primary"
              size="large"
              block
              :loading="isLoading"
              :disabled="isLoading || !isValidEmail(formData.email)"

              @click="triggerSignInEmail(formData.email)"
            >
              {{ isLoading ? $t('user.registration.inProgress') : $t('user.registration.register') }}
            </n-button>
            <n-button size="large" secondary block @click="isEmailSignOnFlow = false">
              <template #icon>
                <Icon name="material-symbols:chevron-left" />
              </template>
              {{ $t('user.registration.button.back.label') }}
            </n-button>

            <n-divider class="!my-2">
              <span class="text-sm opacity-50">
                {{ $t('user.registration.noAccount.description') }}
              </span>
            </n-divider>

            <NuxtLink to="/auth/register">
              <n-button size="large" type="primary" tertiary block>
                <span class="font-medium">
                  {{ $t('user.registration.register.withEmail') }}
                </span>
              </n-button>
            </NuxtLink>
          </div>
        </n-form>
        <div v-else-if="isEmailSignOnFlow === 'finished'">
          <div class="mb-6">
            <h1 class="text-xl mb-4 font-medium">
              {{ $t('user.registration.checkMailBox.description') }}
            </h1>
            <p class="text-base">
              {{ $t('user.registration.emailSent.description') }}
            </p>
          </div>
          <n-button size="large" secondary block @click="isEmailSignOnFlow = false">
            <template #icon>
              <Icon name="material-symbols:chevron-left" />
            </template>
            {{ $t('user.registration.button.back.label') }}
          </n-button>
        </div>
      </div>
    </div>
  </AuthFooterWrapper>
</template>
